.node-picker {
  height: 100%;
  display: flex;
}

.node-picker-col-left {
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  background: var(--dark-base);
  z-index: 5; /* bottom index */
}
.node-picker-header {
  width: 350px;
  text-wrap: wrap;
  font-size: 2rem;
  text-align: center;
  color: beige;
  margin: 0;
}
.mode-span {
  text-decoration: underline;
}
.node-picker-col-left-arrow {
  position: absolute;
  background: beige;
  top: calc(50% - 15px);
  right: -15px;
  transform: rotate(45deg);
  height: 30px;
  width: 30px;
  z-index: 10; /* middle index */
}
.node-picker-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 450px;
  gap: 2rem;
}
.node-picker-autocomplete-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
.node-picker-autocomplete {
  width: 100%;
}
.node-picker-autocomplete-label {
  font-weight: 700;
  color: darkseagreen;
}
.node-picker-autocomplete-label-span {
  margin-bottom: 2px;
}
.node-picker-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
.node-picker-button {
  width: 100% !important;
}

.node-picker-col-right {
  width: 50%;
  height: 100%;
  background: beige;
  padding: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  z-index: 15; /* top index */
  position: relative;
}
.node-picker-entry-label-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 2rem;
}
.node-picker-entry-label {
  all: unset;
  box-sizing: border-box;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 2px;
  border-bottom: 3px solid var(--dark-base);
  color: var(--dark-base);
}
.node-picker-entry-label:focus,
.node-picker-entry-label:hover {
  outline: 2px solid darkseagreen;
  outline-offset: 4px;
}
.disabledPickerLabel {
  border-bottom: 3px solid transparent;
  opacity: 0.4;
}
.soloPickerLabel {
  cursor: auto;
}
.node-picker-entry-content {
  overflow: scroll;
  font-size: 1.2rem;
  text-align: justify;
  line-height: 1.4;
  flex: 1;
}

.node-picker-hr {
  border: none;
  border-top: 2px solid darkslategray;
  width: 75%;
}

@media (max-width: 900px) {
  .node-picker {
    flex-direction: column;
  }
  .node-picker-col-left {
    gap: 1rem;
    width: 100%;
    height: 50%;
    max-height: 50%;
  }
  .node-picker-col-right {
    width: 100%;
    height: 50%;
    max-height: 50%;
    padding: 1rem;
  }

  .node-picker-header {
    font-size: 1.5rem;
    width: 275px;
  }
  .node-picker-controls {
    width: 80%;
    gap: 1rem;
  }
  .node-picker-autocomplete-group {
    flex-direction: row;
  }
  .node-picker-autocomplete-label-span {
    margin-bottom: 2px;
    font-size: 12px;
  }
  .node-picker-col-left-arrow {
    top: unset;
    right: unset;
    left: calc(50% - 15px);
    bottom: -15px;
  }
  .node-picker-entry-label-group {
    gap: 0.5rem;
  }
  .node-picker-entry-label {
    font-size: 1rem;
  }
  .soloPickerLabel {
    font-size: 2rem;
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.node-picker-pulse {
  transition: all 0.5s ease-in;
  animation: pulsate 1.5s infinite;
}
