.graph-container {
  height: 100%;
}
.graph-header {
  height: var(--graph-controls-height);
  background: var(--dark-base);
  padding: 0rem 2rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.graph-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  font-weight: 700;
  color: orange;
  text-align: center;
}

.graph-header-button-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.graph-button {
  border-color: darkseagreen !important;
  color: darkseagreen !important;
  text-transform: none !important;
  font-weight: 600 !important;
  height: 2rem !important;
}

@media (max-width: 850px) {
  .graph-header {
    padding: 0rem 1rem;
    gap: 0.5rem;
  }
  .graph-header-text {
    font-size: 1rem;
  }
  .graph-button {
    font-size: 0.8rem !important;
    height: 1.8rem !important;
  }
}
