.about-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.about-appbar {
  height: var(--bar-height);
  background: darkslategray;
  background: var(--dark-base);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 2rem;
}
.about-tab {
  border-color: darkseagreen !important;
  color: darkseagreen !important;
  text-transform: none !important;
  font-weight: 600 !important;
  height: 2rem !important;
}
.about-tab:hover,
.about-tab:focus {
  opacity: 0.8;
}
.about-content {
  flex: 1;
  overflow: hidden;
  background-color: darkslategray;
  background-color: var(--dark-base);
  color: beige;
  padding: 2rem;
}
.about-header-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.about-header {
  font-size: 52px;
  margin: 0;
}
.about-text {
  text-align: justify;
  line-height: 1.5;
}
.about-pic {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
}
.about-icon {
  font-size: 50px !important;
  border: 1px solid darkseagreen;
  color: darkseagreen;
  border-radius: 50%;
  padding: 2px;
  margin-bottom: -4px;
}

/* SPINOZA */
.about-spinoza {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.about-spinoza-col {
  max-width: 575px;
  overflow: scroll;
}

/* NOTATION */
.about-notation {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.about-notation-col {
  max-width: 500px;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0 0.5rem;
  gap: 1rem;
}
.notation-display {
  display: flex;
  width: 100%;
  max-width: 380px;
  justify-content: space-between;
}
.notation-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.notation-chip-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 135px;
}
.notation-chip {
  display: inline-block;
  width: 2.5rem;
  text-align: center;
  background: beige;
  color: var(--dark-base);
  padding: 2px 0;
  font-weight: 700;
  font-size: 0.8rem;
  border-radius: 4px;
}

/* CREDITS */
.about-credits {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  p {
    margin: 0;
  }
}
.about-credits-col {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 2rem;
}
.about-credits-row {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 1rem;
}
.base-link {
  color: darkseagreen;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 850px) {
  .about-appbar {
    padding: 0 1rem;
    gap: 1rem;
  }
  .about-tab {
    font-size: 0.8rem !important;
    padding: 0 0.5rem !important;
    height: 1.8rem !important;
  }
  .about-content {
    padding: 1rem;
  }
  .about-header {
    font-size: 28px;
  }
  .about-spinoza {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .about-pic {
    height: 35px;
    width: 35px;
    max-height: 35px;
    max-width: 35px;
  }
  .about-icon {
    font-size: 35px !important;
    margin-bottom: -2px;
  }
  .about-credits-col {
    gap: 1.2rem;
  }
}
