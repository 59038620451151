.mode-picker {
  position: relative;
  height: 100%;
  background: var(--dark-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.mode-picker-header {
  font-size: 3rem;
  width: 500px;
  color: darkseagreen;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.mode-picker-button {
  all: unset;

  background: darkseagreen;
  border-radius: 4px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1.2rem 2.2rem;
  cursor: pointer;
  width: 360px;
  color: darkslategray;
}

.mode-picker-button:hover,
.mode-picker-button:focus {
  opacity: 0.8;
}

.mode-picker-button-label {
  font-size: 1.2rem;
  color: var(--dark-base);
  font-weight: 700;

  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mode-picker-button-description {
  font-size: 1rem;
}

@media (max-width: 625px) {
  .mode-picker-header {
    font-size: 2rem;
    width: 400px;
  }
  .mode-picker-button {
    padding: 0.6rem 1.2rem;
    width: 325px;
  }
  .mode-picker-button-label {
    font-size: 1rem;
  }
  .mode-picker-button-description {
    font-size: 0.8rem;
  }
}

.history-drawer {
  transition: all 0.3s;
  background: #7f967f;
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 215px;
  right: -215px;
  top: 0;
  z-index: 10;
}
.history-drawer.open {
  right: 0px;
}
.history-open-button {
  position: absolute;
  top: 2rem !important;
  right: 2rem !important;
}
.history-drawer-appbar {
  background: beige;
  color: var(--dark-base);
  font-weight: 700;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.history-drawer-list {
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.history-item-wrapper {
  position: relative;
}
.history-item {
  all: unset;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  padding: 0.5rem;
  background: var(--dark-base);
  color: beige;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.history-item:focus,
.history-item:hover {
  opacity: 0.9;
}

@media (max-width: 625px) {
  .history-open-button {
    top: 1rem !important;
    right: 1rem !important;
  }
}
