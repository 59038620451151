:root {
  --bar-height: 3.5rem;
  --graph-controls-height: 3.5rem;
  --dark-base: rgb(26, 43, 43);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.error-fallback-container {
  height: 100%;
  background: orange;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.error-fallback-text {
  text-align: justify;
}

/* SCROLLBAR */
/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* Adjust scrollbar width if needed */
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* You can adjust the thumb color or transparency here */
}
/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
