.appbar-container {
  height: var(--bar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: darkslategray;
  color: beige;
}

.appbar-logo {
  margin-right: 1rem;
  font-weight: 900;
}
.appbar-logo-io {
  color: darkseagreen;
}

.appbar-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.appbar-container > .applink-base {
  font-size: 1.2rem;
}

.appbar-arrow-link {
  position: relative;
}
.appbar-arrow {
  display: none;
  position: absolute;
  height: 20px;
  width: 20px;
  background: var(--dark-base);
  transform: rotate(45deg);
  bottom: -30px;
  left: calc(50% - 10px);
}
.appbar-arrow.active {
  display: unset;
}

.appbar-link-text-small {
  display: none;
}

.bmc-icon {
  height: 1.1rem;
  vertical-align: middle;
}

.appbar-patreon-icon {
  display: flex;
  align-items: center;
  height: 15px !important;
  min-height: 15px !important;
  width: 15px !important;
  min-width: 15px !important;
}

/* link buttons */
@media (max-width: 725px) {
  .app-bar-link {
    width: 2.5rem !important;
    min-width: 2.5rem !important;
  }
  .appbar-link-text-large {
    display: none;
  }
  .appbar-link-text-small {
    display: unset;
  }
  .MuiButton-startIcon {
    display: none !important;
  }
  .appbar-book-icon {
    margin-bottom: -5px;
  }
  .bmc-icon {
    height: 1.2rem;
    vertical-align: middle;
    margin-top: -2px;
  }
  .appbar-container > .applink-base {
    font-size: unset;
  }
}

/* everything else */
@media (max-width: 625px) {
  .app-bar-link {
    width: 2rem !important;
    min-width: 2rem !important;
  }
  .appbar-container {
    padding: 0 1rem;
  }
  .appbar-logo {
    margin-right: 0.5rem;
  }
  .appbar-col {
    gap: 0.5rem;
  }
}
