.book-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.book-appbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--bar-height);
  padding: 0 1rem 0 2rem;
  background: darkslategray;
  color: white;
}
.book-appbar-reader-icon {
  font-size: 1.5rem;
}

.book-appbar-col-left,
.book-appbar-col-right {
  flex: 1;
  display: flex;
  align-items: center;
}
.book-appbar-col-right {
  display: flex;
  justify-content: flex-end;
}

.book-appbar-col-left {
  gap: 2rem;
}

.book-appbar-logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  gap: 0.5rem;
}

.book-content {
  position: relative;
  height: calc(100% - var(--bar-height));
  background: darkslategray;
  overflow: hidden;
  display: flex;
}

.book-notes-drawer {
  display: none;
}

.book-entry-notes {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.book-entry-notes-stored-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  overflow-y: scroll;
}
.book-entry-notes-stored {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  gap: 0.5rem;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  background: var(--dark-base);
  word-break: break-all;
  padding: 1rem;
  color: white;
  width: 100%;
}
/* force word breaks */
.book-entry-notes-stored > * {
  min-width: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.note-triangle-corner {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 30px solid var(--dark-base);
  transform: rotate(109deg);
  position: absolute;
  bottom: -10px;
  right: -7px;
}
.note-stored-date {
  font-weight: unset;
  font-size: 0.8rem;
  color: darkseagreen;
  margin-top: 0.8rem;
}
.book-entry-notes-form {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: ghostwhite;
  padding-right: 1rem;
}
.book-entry-form-input {
  outline: none;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
  margin: 0;
  border: none;
  line-height: 1.5;
  background-color: ghostwhite;
  font-family: Arial, sans-serif;
  resize: none;
  border-radius: 4px;
  /* background: darkslategray; */
  height: 100px;
  border-radius: 0;
}
/* prevent endAdornment from breaking */
.notes-stored-right {
  min-width: 40px;
}
.notes-stored-left {
  padding: 0.5rem 0;
}

.book-content-col-left,
.book-content-col-right {
  padding: 1rem;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.book-content-col-right {
  background: #7f967f;
  border-top-left-radius: 16px;
}

.book-entry-name {
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  /* same as text-content */
  width: 100%;
  max-width: 525px;
}
.book-entry-name-text {
  margin-top: -4px;
}
.book-entry-name-icon {
  font-size: 1.5rem !important;
}
.book-entry-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
  width: 100%;
  max-width: 525px;
  position: relative;
}
.book-entry-button-group {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4rem;
}
.book-entry-button {
  all: unset;
  cursor: pointer;
}
.book-entry-content-text {
  flex: 1;
  background: beige;
  padding: 3.5rem 3rem 2rem 3rem;
  border-radius: 16px;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  /* for readability */
  width: 100%;
  max-width: 525px;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 500;
}
.book-proofs-label {
  color: gray;
  margin: 0;
}
.book-entry-proof-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  background: white;
  line-height: 1;
  border-radius: 8px;
  font-size: 0.8rem;
  gap: 0.5rem;
  position: relative;
}
.book-entry-proof-info {
  position: absolute;
  right: -6px;
  top: -6px;
}
.book-entry-proof {
  all: unset;
  cursor: pointer;
  font-weight: bold;
  color: darkseagreen;
  cursor: pointer;
}
.book-entry-proof:hover,
.book-entry-proof:focus {
  text-decoration: underline;
  opacity: 0.8;
}
.book-pagination-icon {
  font-size: 1.5rem !important;
  color: darkseagreen;
}

.book-entry-notes-button {
  display: none !important;
}

@media (max-width: 850px) {
  .book-appbar-reader-icon {
    font-size: 1rem;
  }
  .book-title-ext {
    display: none;
  }
  .book-appbar {
    padding: 0 1rem;
  }
  .book-notes-drawer {
    width: 100%;
    height: 100%;
    background: white;

    display: flex;
    flex-direction: column;

    transition: all 0.3s;
    position: absolute;
    top: 100vh;
    left: 0;
  }

  .book-notes-drawer.open {
    top: 0;
  }
  .book-notes-drawer-appbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--bar-height);
    background: darkseagreen;
    padding: 0 1rem;
  }
  .book-notes-drawer-appbar-title {
    color: darkslategray;
  }
  .book-appbar-col-left,
  .book-appbar-col-right {
    flex: unset;
  }
  .book-appbar-col-left {
    justify-content: space-between;
    font-weight: 700;
    font-size: 1rem;
    gap: 0.5rem;
  }

  .book-content-col-left,
  .book-content-col-right {
    padding: 1rem;
  }

  .book-entry-name {
    font-size: 1.2rem;
  }
  .book-entry-name-icon {
    font-size: 1.5rem !important;
  }
  .book-entry-notes-button {
    display: unset !important;
  }
  .book-entry-content-text {
    font-size: 1rem;
    padding: 2.2rem;
    border-radius: 16px;
  }
  .book-pagination-icon {
    font-size: 1.2rem !important;
  }
  .book-content-col-right {
    display: none;
  }
  .book-appbar-logo-ext {
    display: none;
  }

  .book-entry-button-group {
    display: flex;
    justify-content: space-evenly;
  }
}
